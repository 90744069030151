export enum InteractionsId {
	abandoned = 'ANY_CONSULT',
	notSolved = 'ANY_NOT_SOLVED',
	solved = 'ANY_SOLVED',
	chatConversation = 'CHAT_CONVERSATION',
	chatFault = 'CHAT_FAULT',
	keepWaiting = 'SCHAMAN_CANCEL',
	chatSLACancel = 'SLA_CANCEL',
	chatSLAOutside = 'SLA_OUTSIDE',
	chatSLASolved = 'SLA_SOLVED',
	closed = 'TICKET_CLOSED',
	open = 'TICKET_OPENED',
	mov_faltainfo = 'MOV_FALTAINFO',
	reboot = 'ANTESABAN_REINICIO',
	rebootWait = 'ABAN_REINICIO',
	ChatHistoricClose = 'CHAT_HISTORIC_CLOSE',
	ChatHistoricOpen = 'CHAT_HISTORIC_OPEN',
	ChatCloseSeg = 'CHAT_CLOSE_SEG',
	ChatConversationGarantia1 = 'CHAT_CONVERSATION_GARANTIA1',
	ChatConversationGarantia2 = 'CHAT_CONVERSATION_GARANTIA2',
	ChatConversationGarantia3 = 'CHAT_CONVERSATION_GARANTIA3',
	ChatConversationGarantia4 = 'CHAT_CONVERSATION_GARANTIA4',
	ChatGarantia1L = 'CHAT_GARANTIA1L',
	ChatGarantia2L = 'CHAT_GARANTIA2L',
	ChatGarantia3L = 'CHAT_GARANTIA3L',
	ChatGarantia4L = 'CHAT_GARANTIA4L',
	ChatHistoricGarantia1 = 'CHAT_HISTORIC_GARANTIA1',
	ChatHistoricGarantia2 = 'CHAT_HISTORIC_GARANTIA2',
	ChatHistoricGarantia3 = 'CHAT_HISTORIC_GARANTIA3',
	ChatHistoricGarantia4 = 'CHAT_HISTORIC_GARANTIA4',
	SolvedGarantia = 'SOLVED_GARANTIA',
	ChatGarantia1 = 'CHAT_GARANTIA1',
	ChatGarantia2 = 'CHAT_GARANTIA2',
	ChatGarantia3 = 'CHAT_GARANTIA3',
	ChatGarantia4 = 'CHAT_GARANTIA4',
	ChatGarantia = 'CHAT_GARANTIA',
	ChatGarantiaL = 'CHAT_GARANTIAL',
	ChatHistoricGarantia = 'CHAT_HISTORIC_GARANTIA',
	SLA_SOLVED = 'SLA_SOLVED',
	SLA_OUTSIDE = 'SLA_OUTSIDE',
	SLA_CANCEL = 'SLA_CANCEL',
	Fibre_Solved = 'ABAN_HYPERNET_SOLVED',
	Fibre_Not_Solved = 'ABAN_HYPERNET_NOT_SOLVED',
	TV_Solved = 'ABAN_HYPERTV_SOLVED',
	TV_Not_Solved = 'ABAN_HYPERTV_NOT_SOLVED',
	Fijo_Solved = 'ABAN_HYPERTF_SOLVED',
	Fijo_Not_Solved = 'ABAN_HYPERTF_NOT_SOLVED',
	Hogar5G_Solved = 'ABAN_HYPER5G_SOLVED',
	Hogar5G_Not_Solved = 'ABAN_HYPER5G_NOT_SOLVED',
	FAQLink = 'Control/Enlace',
}
