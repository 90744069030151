import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoamingService } from '../../app/shared/services/roaming.service';
import { tagging } from '../../config/tagging-config';
import { AppService } from '../app.service';
import { StorageService } from '../core/services/storage.service';
import { SubscriptionService } from '../core/services/subscription.service';
import { TaggingHelperService } from '../core/services/tagging.helper.service';
import { Roaming, roamingProductmangment, roamingToggles } from '../shared/constants/defines';
import { ClientTypology } from '../shared/enums/clientTopology.enum';
import { LineRestrictionTypes } from '../shared/enums/line-restriction-types.enum';
import { RestrictionsStatus } from '../shared/enums/restrictions-status.enum';
import { RoamingBundleKeys } from '../shared/enums/roamingBundleKeys';
import { RoamingBundleTypes } from '../shared/enums/roamingBundleTypes';
import { IDynamicTray } from '../shared/models/dynamic-tray.model';
import { IGenericSuccessErrorTray } from '../shared/models/generic-success-error-tray.model';
import { PaymentTaggingPageName, TaggingJourney, TrackActions, TrackActionsObject } from '../shared/models/payment';
import { RoamingCountryModel } from '../shared/models/roamin-country.model';
import {
	ProductDeatis,
	ProductItem,
	ProductOfferingPostObject,
	RoamingAction,
} from '../shared/models/roaming-productOrderItem';
import { RoamingBundle } from '../shared/models/roamingBundle.model';
import { RoamingBundleCard } from '../shared/models/roamingBundleCard.model';
import { RoamingBundleDeatils, romaingBundleDeailType } from '../shared/models/romaingBundleDeatils.model';
import { DynamicTrayService } from '../shared/services/dynamic-tray.service';
import { PoliticaZoneOneComponent } from './politica-zone-one/politica-zone-one.component';
import { ZoneOneTrayComponent } from './zone-one-tray/zone-one-tray.component';

@Injectable()
export class RoamingHelper {
	public closeTraySubject: Subject<boolean> = new Subject<boolean>();
	public selectedMsidnRoaming: string;
	public fisrtSelectedCountry: RoamingCountryModel;
	public secondSelectedCountry: RoamingCountryModel;
	public tarrifCode: string;
	public legacyUser: boolean;
	public customerAccountId: string;
	public roamingBundleList: RoamingBundle[];
	public roamingBundleCards: RoamingBundleCard[];
	public roamingLoadingMode: boolean;
	public callingProductOfferingSucess: boolean;
	public callingRestrictionApiSucess: boolean;
	public callingRestrictionApifinished: boolean;
	public showOtherCardsDisabled: boolean = false;
	public showMainRoamingDisabled: boolean = false;
	public recommendedCard: RoamingBundleCard = new RoamingBundleCard();
	public worldCard: RoamingBundleCard = new RoamingBundleCard();
	public NLUTariffs: string[];
	hideMainToggle: boolean;
	hideSecondryToggles: boolean;

	constructor(
		public translate: TranslateService,
		public dynamicTrayService: DynamicTrayService,
		public subscriptionService: SubscriptionService,
		public roamingService: RoamingService,
		private appTheme: AppService,
		private tagging: TaggingHelperService,
		private storageService: StorageService
	) {
		this.getNLUTariffs();
	}

	/** will return Tray Zone one Tray data */
	drawZoneOneTray(): void {
		/** draw Tray of Zone 1 */
		let zoneOneTrayTitle: string;
		let zoneOneTrayBtnTxt: string;
		this.translate.get('v10.roaming.tray.countriesZone1').subscribe((data) => {
			zoneOneTrayTitle = data.title;
			zoneOneTrayBtnTxt = data.button;
		});
		const trayZoneOneData: IDynamicTray = {
			basicTrayData: {
				title: zoneOneTrayTitle,
				secondaryButton: zoneOneTrayBtnTxt,
			},
			closeButtonAction: () => {
				this.dynamicTrayService.close();
			},
			secondaryButtonAction: () => {
				this.dynamicTrayService.close();
			},
			overlayID: 'zone-one-tray',
		};
		this.dynamicTrayService.open(trayZoneOneData, ZoneOneTrayComponent);
	}
	drawPoliticaZoneOneTray(): void {
		/** draw Tray of Zone 1 politica */
		let zoneOnePoliticaTrayTitle: string;
		this.translate.get('v10.roaming.tray.fairUsePolicyZone1').subscribe((data) => {
			zoneOnePoliticaTrayTitle = data.title;
		});
		const trayPoliticaZoneOneData: IDynamicTray = {
			basicTrayData: {
				title: zoneOnePoliticaTrayTitle,
				isScrollableTray: true,
			},
			closeButtonAction: () => {
				this.dynamicTrayService.close();
			},
			backButtonAction: () => {
				this.dynamicTrayService.close();
			},
			showBackArrowWhenScrollToFullScreen: true,
		};
		this.dynamicTrayService.open(trayPoliticaZoneOneData, PoliticaZoneOneComponent);
	}
	/** method for get roaming bundle */
	getRoamingBundles(): Observable<RoamingBundleCard[]> {
		this.setZoneLegacyUser();
		const zoneId: string = this.fisrtSelectedCountry.zone;
		const zoneId2: string =
			this.secondSelectedCountry.prefix === Roaming.SpainPrefix ? Roaming.ZoneZero : this.secondSelectedCountry.zone;
		return this.roamingService
			.getRoamingEligibleProductOffering(
				this.customerAccountId,
				this.tarrifCode,
				zoneId,
				zoneId2,
				this.selectedMsidnRoaming,
				this.fisrtSelectedCountry.zone
			)
			.pipe(
				map((res) => {
					this.roamingBundleList = [...res];
					this.getMissingdataFromWcs(this.roamingBundleList);
					this.mapBundelToComponentModel(this.roamingBundleList);
					this.worldCard = this.roamingBundleCards.find((card) => card.hideTitleAndBorder);
					this.recommendedCard = this.roamingBundleCards.find((card) => !card.hideTitleAndBorder);
					return this.roamingBundleCards;
				})
			);
	}
	getNLUTariffs(): void {
		this.translate.get('v10.roaming.overrideZoneTariff').subscribe((data) => {
			this.NLUTariffs = Object.values(data);
			this.legacyUser = !this.NLUTariffs.includes(this.tarrifCode);
		});
	}
	getMissingdataFromWcs(roamingBundleList: RoamingBundle[]): void {
		roamingBundleList.forEach((bundle) => {
			this.getMissingbundleDeatilsFromWsc(bundle);
		});
	}
	/** method to get bunlde deatils from wcs in case it's not came from api  */
	getMissingbundleDeatilsFromWsc(tarrif: RoamingBundle): void {
		tarrif.deatils.forEach((bunlde) => {
			const bundletype: string = bunlde.type;
			const detailsObj: RoamingBundleDeatils = tarrif.deatils.find((element) => element.type === bundletype);

			this.translate.get('v10.roaming.landing.tarrifs').subscribe((data) => {
				const defaultBundle =
					{
						[RoamingBundleTypes.BonoEEUU]: data[RoamingBundleKeys.TARIFA_EEUU]?.[bundletype],
						[RoamingBundleTypes.Mundo]: data[RoamingBundleKeys.TARIFA_WORLD]?.[bundletype],
						[RoamingBundleTypes.Roaming]: data[RoamingBundleKeys.TARIFA_WORLD]?.[bundletype],
						[RoamingBundleTypes.World]: data[RoamingBundleKeys.TARIFA_WORLD]?.[bundletype],
					}[tarrif.type] ?? {};

				detailsObj.title = detailsObj.title || defaultBundle.name;
				detailsObj.icon = detailsObj.icon || defaultBundle.icon;
				detailsObj.price = detailsObj.price || defaultBundle.price;
				detailsObj.description = this.getBundledescription(tarrif, detailsObj, data);
			});
		});
	}
	/** method to map roamingBundleList to roamingBundleCards to be used in component directly  */
	mapBundelToComponentModel(roamingBundleList: RoamingBundle[]): void {
		this.roamingBundleCards = new Array<RoamingBundleCard>(this.roamingBundleList.length);
		roamingBundleList.forEach((item: RoamingBundle, index: number) => {
			const card = new RoamingBundleCard();
			card.bundleDeatils = [...item.deatils];
			card.status = item.status;
			card.hideTitleAndBorder = item.type === RoamingBundleTypes.World;
			this.translate.get('v10.roaming.landing.tarrifs').subscribe((data) => {
				const roamingType = RoamingBundleKeys[item.type] ?? RoamingBundleKeys.TARIFA_WORLD;
				card.linkText = data[roamingType]?.moreDetailsButton;
				card.textBelow = data[roamingType]?.description;
				card.thirdTitle = data[roamingType]?.subtitle1;
				if (item.type === RoamingBundleTypes.World) {
					if (this.isZoneOneLegacy(this.fisrtSelectedCountry.zone)) {
						if (item['isLimitedTarrif']) {
							this.getSubtitleForLimitedTarrif(this.roamingBundleCards.indexOf(card));
						} else {
							card.hint = data[roamingType]?.unlimitedTarrifDescription;
							card.thirdTitle = this.isZoneOneLegacy(this.secondSelectedCountry.zone)
								? data.world.subtitle4
								: data.world.subtitle3;
						}
					}
					card.linkTextBottomDivider = true;
				} else if ([RoamingBundleTypes.Mundo, RoamingBundleTypes.BonoEEUU].includes(item.type)) {
					card.cardTitle = data[roamingType]?.header;
					card.SecondTitle = data[roamingType]?.title;
					card.pricePerUse = data[roamingType]?.pricePerUse;
					card.thirdTitle = card.thirdTitle?.replace('{0}', data[roamingType]?.pricePerUse ?? '');
					card.linkTextBottomDivider = false;
					this.getMoundoStatusLabel(data, item, card);
				}
				this.roamingBundleCards[index] = card;
			});
		});
	}
	/** method to set status label
	 * @param data is data from WCS
	 * @param item is romaing bundle
	 */
	getMoundoStatusLabel(data: any, item: RoamingBundle, bundlecard: RoamingBundleCard): void {
		switch (item.status) {
			case RestrictionsStatus.active:
				bundlecard.leftTitleText = data.activeStatus;
				bundlecard.btnText = data.buttonList.deactivateButton;
				break;
			case RestrictionsStatus.inactive:
				bundlecard.leftTitleText = data.deactiveStatus;
				bundlecard.btnText = data.buttonList.activateButton;
				break;
			case RestrictionsStatus.PendingActive:
				bundlecard.leftTitleText = data.pendingActivationStatus;
				bundlecard.btnText = data.buttonList.activateButton;

				break;
			case RestrictionsStatus.PendingInActive:
				bundlecard.leftTitleText = data.pendingDeactivationStatus;
				bundlecard.btnText = data.buttonList.deactivateButton;

				break;
		}
	}
	/**method to get bundle deatils description as it sometimes came from api and sometime else it came from wcs */
	getBundledescription(tariff: RoamingBundle, details: RoamingBundleDeatils, data: any): string {
		const isCallType: boolean = [romaingBundleDeailType.callssent, romaingBundleDeailType.callsreceived].includes(
			details.type
		);
		const roamingCurrentKey: string = RoamingBundleKeys[tariff.type];
		const detailType: string = details.type + (isCallType && details.establishmentPrice ? 'establishment' : '');
		const zoneToCompare = {
			[RoamingBundleTypes.World]: '1',
			[RoamingBundleTypes.Mundo]: '2',
			[RoamingBundleTypes.BonoEEUU]: '5',
		}[tariff.type];

		let description: string = data[roamingCurrentKey][detailType]?.description ?? '';

		if (isCallType && details.establishmentPrice) {
			description = description.replace('{0}', details.establishmentPrice + details.establishmentUnit);
		} else if (this.fisrtSelectedCountry.zone === zoneToCompare && details.type === romaingBundleDeailType.data) {
			description = ' ';
		}

		return description;
	}

	/** method to active/DeActivate moundo tarrif */
	toggleproductOrderingManagement(action: RoamingAction): Observable<any> {
		const reqBody: ProductOfferingPostObject = new ProductOfferingPostObject();
		reqBody.relatedParty[0].id = this.customerAccountId;
		const firstItem: ProductItem = new ProductItem();
		firstItem.id = '1';
		firstItem.action = action;
		reqBody.productOrderItem = new Array<ProductItem>();
		reqBody.productOrderItem.push(firstItem);
		this.roamingService.recommendedBundleProductOffering.forEach((bundle, index) => {
			const item: ProductItem = new ProductItem();
			item.id = '1.' + (index + 1).toString();
			item.action = action;
			item.category[0].id = roamingProductmangment.RoamingCategory.id_CatalogElement;
			item.category[0].name = roamingProductmangment.RoamingCategory.name;
			item.product = new ProductDeatis();
			item.product.productSerialNumber = this.selectedMsidnRoaming;
			item.product.isBundle = true;
			item.product.productOffering.id = this.roamingService.recommendedBundleProductOffering[index].id;
			item.product.productCharacteristic[0].name = roamingProductmangment.instance;
			item.product.productCharacteristic[0].value = this.subscriptionService.customerData.services.find(
				(item) => item.id === this.selectedMsidnRoaming
			)?.instance;
			reqBody.productOrderItem.push(item);
		});
		return this.roamingService.toggleproductOrderingManagement(reqBody).pipe(map((res) => res));
	}

	getSubtitleForLimitedTarrif(index: number): void {
		this.translate.get('v10.roaming.landing.tarrifs').subscribe((data) => {
			this.roamingBundleCards[index].thirdTitle = data.world.subtitle1;
			if (this.secondSelectedCountry.zone === Roaming.ZoneOne) {
				this.roamingBundleCards[index].thirdTitle = data.world.subtitle2;
			}
		});
	}

	setTrayBoldCenterTitleAndImg(
		roamingType: LineRestrictionTypes,
		roaimgStatus: RestrictionsStatus,
		confirmationTrayData: IGenericSuccessErrorTray,
		roamingWcsObject: any
	): void {
		if (roamingType === LineRestrictionTypes.roaming) {
			this.checkStatusActiveOrNot(
				roaimgStatus,
				confirmationTrayData,
				roamingWcsObject.deactivationConfirmationMsg.title,
				roamingWcsObject.activationConfirmationMsg.title,
				this.appTheme.getImgFullPath(roamingWcsObject.deactivationConfirmationMsg.icon),
				this.appTheme.getImgFullPath(roamingWcsObject.activationConfirmationMsg.icon)
			);
		} else if (roamingType === LineRestrictionTypes.incomingRoaming) {
			this.checkStatusActiveOrNot(
				roaimgStatus,
				confirmationTrayData,
				roamingWcsObject.ingoing.deactivation.title,
				roamingWcsObject.ingoing.activation.title,
				this.appTheme.getImgFullPath(roamingWcsObject.ingoing.icon),
				this.appTheme.getImgFullPath(roamingWcsObject.ingoing.icon)
			);
		} else if (roamingType === LineRestrictionTypes.international) {
			this.checkStatusActiveOrNot(
				roaimgStatus,
				confirmationTrayData,
				roamingWcsObject.outgoing.deactivation.title,
				roamingWcsObject.outgoing.activation.title,
				this.appTheme.getImgFullPath(roamingWcsObject.outgoing.icon),
				this.appTheme.getImgFullPath(roamingWcsObject.outgoing.icon)
			);
		} else if (roamingType === LineRestrictionTypes.data) {
			this.checkStatusActiveOrNot(
				roaimgStatus,
				confirmationTrayData,
				roamingWcsObject.data.deactivation.title,
				roamingWcsObject.data.activation.title,
				this.appTheme.getImgFullPath(roamingWcsObject.data.icon),
				this.appTheme.getImgFullPath(roamingWcsObject.data.icon)
			);
		}
	}

	checkStatusActiveOrNot(
		roaimgStatus: RestrictionsStatus,
		confirmationTrayData: IGenericSuccessErrorTray,
		deactiveTitle: string,
		activeTitle: string,
		deactiveImg: string,
		activeImg: string
	): void {
		// bold center title changes according to status
		if (roaimgStatus === RestrictionsStatus.active) {
			confirmationTrayData.boldCenterTitle = deactiveTitle;
			confirmationTrayData.staticImagePath = deactiveImg;
		}
		// incase status is inactive
		else {
			confirmationTrayData.boldCenterTitle = activeTitle;
			confirmationTrayData.staticImagePath = activeImg;
		}
	}

	setConfirmationTrayTitle(
		roamingType: LineRestrictionTypes,
		newStatus: RestrictionsStatus,
		roamingWcsObject: any
	): string {
		let title: string = '';
		if (roamingType === LineRestrictionTypes.roaming) {
			title = this.checkConfirmationTrayStatus(
				newStatus,
				roamingWcsObject.successMsg.title,
				roamingWcsObject.deactivationSuccessMsg.title
			);
		} else if (roamingType === LineRestrictionTypes.incomingRoaming) {
			title = this.checkConfirmationTrayStatus(
				newStatus,
				roamingWcsObject.ingoing.activation.confirmationTitle,
				roamingWcsObject.ingoing.deactivation.confirmationTitle
			);
		} else if (roamingType === LineRestrictionTypes.international) {
			title = this.checkConfirmationTrayStatus(
				newStatus,
				roamingWcsObject.outgoing.activation.confirmationTitle,
				roamingWcsObject.outgoing.deactivation.confirmationTitle
			);
		} else if (roamingType === LineRestrictionTypes.data) {
			title = this.checkConfirmationTrayStatus(
				newStatus,
				roamingWcsObject.data.activation.confirmationTitle,
				roamingWcsObject.data.confirmation.title
			);
		}
		return title;
	}

	checkConfirmationTrayStatus(newStatus: RestrictionsStatus, successActive: string, successDeactive: string): string {
		return newStatus === RestrictionsStatus.active ? successActive : successDeactive;
	}

	/**
	 * check if roaming toggle is inactive or pending to dim secondary toggles.
	 * check if one of secondary toggles is pending and the two others are inactive to dim secondary toggles.
	 */
	dimSecondaryToggles(
		roamingStatus: RestrictionsStatus,
		incomingRoamingStatus: RestrictionsStatus,
		outgoingStatus: RestrictionsStatus,
		dataStatus: RestrictionsStatus
	): void {
		this.showOtherCardsDisabled = false;
		if (
			roamingStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase() ||
			roamingStatus?.toLowerCase().includes(RestrictionsStatus.pending) ||
			this.checkSecondaryTogglesStatus(incomingRoamingStatus, outgoingStatus, dataStatus)
		) {
			this.showOtherCardsDisabled = true;
			this.hideMainToggle = false;
			this.hideSecondryToggles = true;
		}
	}

	/**
	 * check if all secondary toggles are inactive to dim main toggle.
	 * check if main roaming toggle is pending to also dim main toggle.
	 * check if one of secondary toggles is pending and the two others are inactive to dim main toggle.
	 */
	dimMainRoamingToggle(
		roamingStatus: RestrictionsStatus,
		incomingRoamingStatus: RestrictionsStatus,
		outgoingStatus: RestrictionsStatus,
		dataStatus: RestrictionsStatus
	): void {
		this.showMainRoamingDisabled = false;
		if (roamingStatus?.toLowerCase() === RestrictionsStatus.PendingInActive) {
			this.hideMainToggle = false;
			this.hideSecondryToggles = true;
			this.showMainRoamingDisabled = true;
		} else if (
			incomingRoamingStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase() &&
			outgoingStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase() &&
			dataStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase()
		) {
			this.hideMainToggle = true;
			this.showMainRoamingDisabled = true;
		} else {
			this.checkToggls(roamingStatus, incomingRoamingStatus, outgoingStatus, dataStatus);
		}
	}

	checkToggls(
		roamingStatus: RestrictionsStatus,
		incomingRoamingStatus: RestrictionsStatus,
		outgoingStatus: RestrictionsStatus,
		dataStatus: RestrictionsStatus
	): void {
		if (
			roamingStatus?.toLowerCase() === RestrictionsStatus.PendingActive &&
			this.checkSecondaryTogglesStatus(incomingRoamingStatus, outgoingStatus, dataStatus)
		) {
			this.hideMainToggle = false;
			this.hideSecondryToggles = false;
			this.showMainRoamingDisabled = true;
		} else if (
			roamingStatus?.toLowerCase() === RestrictionsStatus.PendingActive &&
			!this.checkSecondaryTogglesStatus(incomingRoamingStatus, outgoingStatus, dataStatus)
		) {
			this.hideMainToggle = false;
			this.hideSecondryToggles = false;
			this.showMainRoamingDisabled = true;
		} else if (
			roamingStatus?.toLowerCase() === RestrictionsStatus.active.toLocaleLowerCase() &&
			this.checkSecondaryTogglesStatus(incomingRoamingStatus, outgoingStatus, dataStatus)
		) {
			this.hideMainToggle = true;
			this.hideSecondryToggles = false;
			this.showMainRoamingDisabled = true;
		} else if (
			roamingStatus?.toLowerCase() === RestrictionsStatus.active.toLocaleLowerCase() &&
			this.checkSecondaryTogglesStatusPendingActivation(incomingRoamingStatus, outgoingStatus, dataStatus)
		) {
			this.hideMainToggle = false;
			this.hideSecondryToggles = false;
			this.showMainRoamingDisabled = true;
			this.showOtherCardsDisabled = true;
		}
	}
	/**
	 * check if one of secondary toggles is pending and the two others are inactive.
	 * to dim main toggle and secondary toggles.
	 */
	checkSecondaryTogglesStatus(
		incomingRoamingStatus: RestrictionsStatus,
		outgoingStatus: RestrictionsStatus,
		dataStatus: RestrictionsStatus
	): boolean {
		if (
			(incomingRoamingStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase() &&
				outgoingStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase() &&
				dataStatus?.toLowerCase() === RestrictionsStatus.PendingInActive.toLocaleLowerCase()) ||
			(incomingRoamingStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase() &&
				outgoingStatus?.toLowerCase() === RestrictionsStatus.PendingInActive.toLocaleLowerCase() &&
				dataStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase()) ||
			(incomingRoamingStatus?.toLowerCase() === RestrictionsStatus.PendingInActive.toLocaleLowerCase() &&
				outgoingStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase() &&
				dataStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase())
		) {
			return true;
		}
		return false;
	}

	checkSecondaryTogglesStatusPendingActivation(
		incomingRoamingStatus: RestrictionsStatus,
		outgoingStatus: RestrictionsStatus,
		dataStatus: RestrictionsStatus
	): boolean {
		if (
			(incomingRoamingStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase() &&
				outgoingStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase() &&
				dataStatus?.toLowerCase() === RestrictionsStatus.PendingActive.toLocaleLowerCase()) ||
			(incomingRoamingStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase() &&
				outgoingStatus?.toLowerCase() === RestrictionsStatus.PendingActive.toLocaleLowerCase() &&
				dataStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase()) ||
			(incomingRoamingStatus?.toLowerCase() === RestrictionsStatus.PendingActive.toLocaleLowerCase() &&
				outgoingStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase() &&
				dataStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase())
		) {
			return true;
		}
		return false;
	}

	opentrayTaggingOutgoingAndData(roamingType: LineRestrictionTypes, roaimgStatus: RestrictionsStatus): void {
		if (roamingType === LineRestrictionTypes.international) {
			this.tagging.view(
				roaimgStatus === RestrictionsStatus.inactive ? tagging.outgoingActivationTray : tagging.outgoingDeActivationTray
			);
		} else if (roamingType === LineRestrictionTypes.data) {
			this.tagging.view(
				roaimgStatus === RestrictionsStatus.inactive ? tagging.dataActivationTray : tagging.dataDeActivationTray
			);
		}
	}

	clickOnBtnCardTaggingOutgoingAndData(roamingType: LineRestrictionTypes, roaimgStatus: RestrictionsStatus): void {
		if (roamingType === LineRestrictionTypes.international) {
			roaimgStatus === RestrictionsStatus.inactive
				? this.applyTaggingForToggleClick(
						tagging.RoamingActions.outgoing.activeBtnCard,
						tagging.outgoingActivationJourney.firstStep,
						tagging.activacionOutgoing
				  )
				: this.applyTaggingForToggleClick(
						tagging.RoamingActions.outgoing.desActiveBtnCard,
						tagging.outgoingDeActivationJourney.firstStep,
						tagging.desactivacionOutgoing
				  );
		} else if (roamingType === LineRestrictionTypes.data) {
			roaimgStatus === RestrictionsStatus.inactive
				? this.applyTaggingForToggleClick(
						tagging.RoamingActions.data.activeBtnCard,
						tagging.dataActivationJourney.firstStep,
						tagging.activacionData
				  )
				: this.applyTaggingForToggleClick(
						tagging.RoamingActions.data.desActiveBtnCard,
						tagging.dataDeActivationJourney.firstStep,
						tagging.desactivacionData
				  );
		}
	}

	applyTaggingForToggleClick(
		taggingObj: TrackActionsObject,
		taggingObjData: TrackActions,
		tagPageName: { page_name: string }
	): void {
		this.tagging.track(taggingObj.eventName, taggingObj.data);
		// tagging start of joureny
		const tag: TaggingJourney = Object.assign({}, taggingObjData.data);
		tag.journey_category = this.tagging.getUserType(this.storageService.userProfile.customerType, ClientTypology);
		this.tagging.track(taggingObjData.eventName, tag, tagPageName);
	}

	sucessErrorTrayTagingOutgoingAndData(
		isSuccess: boolean,
		roamingType: LineRestrictionTypes,
		newStatus: RestrictionsStatus,
		error?: HttpErrorResponse
	): void {
		if (roamingType === LineRestrictionTypes.international) {
			newStatus === RestrictionsStatus.active
				? this.taggingOutgoingpatchingRequestActivation(isSuccess, error)
				: this.taggingOutgoingpatchingRequestDeActivation(isSuccess, error);
		} else if (roamingType === LineRestrictionTypes.data) {
			newStatus === RestrictionsStatus.active
				? this.taggingDatapatchingRequestActivation(isSuccess, error)
				: this.taggingDatapatchingRequestDeActivation(isSuccess, error);
		}
	}

	applyTaggingFortraySuccess(
		taggingPageNameObject: PaymentTaggingPageName,
		taggingObjData: TrackActions,
		tagPageName: { page_name: string }
	): void {
		this.tagging.view(taggingPageNameObject);
		// tagging sucess of joureny
		const tag: TaggingJourney = Object.assign({}, taggingObjData.data);
		tag.journey_category = this.tagging.getUserType(this.storageService.userProfile.customerType, ClientTypology);
		this.tagging.track(taggingObjData.eventName, tag, tagPageName);
	}

	applyTaggingFortrayFail(
		taggingPageNameObject: PaymentTaggingPageName,
		taggingObjData: TrackActions,
		tagPageName: { page_name: string },
		error?: HttpErrorResponse
	): void {
		this.tagging.view(taggingPageNameObject);
		// tagging start of joureny
		const tag: TaggingJourney = Object.assign({}, taggingObjData.data);
		tag.journey_category = this.tagging.getUserType(this.storageService.userProfile.customerType, ClientTypology);
		this.handleErrorDataForToggles(tag, error);
		this.tagging.track(taggingObjData.eventName, tag, tagPageName);
	}

	taggingOutgoingpatchingRequestActivation(isSuccess: boolean, error?: HttpErrorResponse): void {
		isSuccess
			? this.applyTaggingFortraySuccess(
					tagging.outgoingSucessActive,
					tagging.outgoingActivationJourney.sucess,
					tagging.activacionOutgoing
			  )
			: this.applyTaggingFortrayFail(
					tagging.outgoingFailActive,
					tagging.outgoingActivationJourney.error,
					tagging.activacionOutgoing,
					error
			  );
	}

	taggingOutgoingpatchingRequestDeActivation(isSuccess: boolean, error?: HttpErrorResponse): void {
		isSuccess
			? this.applyTaggingFortraySuccess(
					tagging.outgoingSucessDeActive,
					tagging.outgoingDeActivationJourney.sucess,
					tagging.desactivacionOutgoing
			  )
			: this.applyTaggingFortrayFail(
					tagging.outgoingFailDeActive,
					tagging.outgoingDeActivationJourney.error,
					tagging.desactivacionOutgoing,
					error
			  );
	}

	taggingDatapatchingRequestActivation(isSuccess: boolean, error?: HttpErrorResponse): void {
		isSuccess
			? this.applyTaggingFortraySuccess(
					tagging.dataSucessActive,
					tagging.dataActivationJourney.sucess,
					tagging.activacionData
			  )
			: this.applyTaggingFortrayFail(
					tagging.dataFailActive,
					tagging.dataActivationJourney.error,
					tagging.activacionData,
					error
			  );
	}

	taggingDatapatchingRequestDeActivation(isSuccess: boolean, error?: HttpErrorResponse): void {
		isSuccess
			? this.applyTaggingFortraySuccess(
					tagging.dataSucessDeActive,
					tagging.dataDeActivationJourney.sucess,
					tagging.desactivacionData
			  )
			: this.applyTaggingFortrayFail(
					tagging.dataFailDeActive,
					tagging.dataDeActivationJourney.error,
					tagging.desactivacionData,
					error
			  );
	}

	taggingToggleClickOutgoingAndData(roamingType: LineRestrictionTypes, roaimgStatus: RestrictionsStatus): void {
		if (roamingType === LineRestrictionTypes.international || roamingType === LineRestrictionTypes.data) {
			const type: string =
				roamingType === LineRestrictionTypes.international ? roamingToggles.outgoing : roamingToggles.data;
			if (roaimgStatus === RestrictionsStatus.inactive) {
				this.tagging.track(
					tagging.RoamingActions[type].activateBtnTray.eventName,
					tagging.RoamingActions[type].activateBtnTray.data,
					roamingType === LineRestrictionTypes.international
						? tagging.outgoingActivationTray
						: tagging.dataActivationTray
				);
			} else if (roaimgStatus === RestrictionsStatus.active) {
				this.tagging.track(
					tagging.RoamingActions[type].desactivationBtnTray.eventName,
					tagging.RoamingActions[type].desactivationBtnTray.data,
					roamingType === LineRestrictionTypes.international
						? tagging.outgoingDeActivationTray
						: tagging.dataDeActivationTray
				);
			}
		}
	}

	taggingToggleClickCancelOutgoingAndData(
		roamingType: LineRestrictionTypes,
		roaimgStatus: RestrictionsStatus,
		isCancel: boolean
	): void {
		if ((roamingType === LineRestrictionTypes.international || roamingType === LineRestrictionTypes.data) && isCancel) {
			const type: string =
				roamingType === LineRestrictionTypes.international ? roamingToggles.outgoing : roamingToggles.data;
			if (roaimgStatus === RestrictionsStatus.inactive) {
				this.tagging.track(
					tagging.RoamingActions[type].CancelBtnTray.eventName,
					tagging.RoamingActions[type].CancelBtnTray.data,
					roamingType === LineRestrictionTypes.international
						? tagging.outgoingActivationTray
						: tagging.dataActivationTray
				);
			} else if (roaimgStatus === RestrictionsStatus.active) {
				this.tagging.track(
					tagging.RoamingActions[type].desactivationCancelBtnTray.eventName,
					tagging.RoamingActions[type].desactivationCancelBtnTray.data,
					roamingType === LineRestrictionTypes.international
						? tagging.outgoingDeActivationTray
						: tagging.dataDeActivationTray
				);
			}
		}
	}

	handleErrorDataForToggles(tag: TaggingJourney, error: HttpErrorResponse): void {
		tag.journey_error_category = this.tagging.setErrorTypeValue(error);
		tag.journey_error_code = error?.error?.ecode ?? '';
		tag.journey_error_description = this.translate.instant(
			'v10.roaming.landing.roamingPermissions.tray.failure.description'
		);
	}

	public isZoneOneLegacy(zone: string): boolean {
		if (zone === Roaming.ZoneOne || zone === Roaming.ZoneSeven) return true;

		if (zone === Roaming.ZoneFive && this.legacyUser) return true;

		return false;
	}

	public setZoneLegacyUser() {
		if (this.legacyUser) {
			this.fisrtSelectedCountry.zone = this.fisrtSelectedCountry.overrideZone
				? this.fisrtSelectedCountry.overrideZone
				: this.fisrtSelectedCountry.zone;
			this.secondSelectedCountry.zone = this.secondSelectedCountry.overrideZone
				? this.secondSelectedCountry.overrideZone
				: this.secondSelectedCountry.zone;
		}
	}
}
