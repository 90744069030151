import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Breakpoints } from '@mva10/mva10-angular';
import { BubbleHelpConfiguration } from 'src/app/shared/models/bubbleHelpConfiguration';
import { modulesStartPoints } from '../../../config/modules-start-points';
import { config } from '../../../config/pages-config';
import { AppService } from '../../app.service';
import { TopNavgationBarIconsName } from '../../shared/constants/defines';
import { BackNavigation } from '../../shared/enums/back-navigation.enum';
import { MainHeaderIcon } from '../../shared/models/main-header.model';
import { CrossFunctionalityService } from '../../shared/services/cross-functionality.service';
import { NotificationInboxService } from '../../shared/services/notification-inbox.service';
import { LocationService } from './location.service';
import { StorageService } from './storage.service';

@Injectable()
export class CommonService {

	public showMenu = false;
	public showMenuButton = false;
	public showCloseButton = false;
	public showChatInBubbleHelp = false;
	public showFAQsInBubbleHelp = false;
	public autoScrollDashboard = new EventEmitter();
	public showContactUS = false;
	public showSurvey = false;
	public overrideBackHandling = false;
	public backBtnSubject = new EventEmitter();
	public leftIcon;
	public rightIcon;
	public currentPageBubbleHelpConfig: BubbleHelpConfiguration;
	/* Please, return originRoute value to dashboard onDestroy */
	public originRoute: string = config.dashboard.route;
	/* Please, return originRouteParams value to null onDestroy */
	public originRouteParams: { [key: string]: string } = null;
	isLeftIconLogo: boolean;
	BREAKPOINTS: typeof Breakpoints = Breakpoints;

	/** New Main Header */
	mainHeaderLeftIcons: MainHeaderIcon[] = [];
	mainHeaderRightIcons: MainHeaderIcon[] = [];
	isDashboard: boolean;
	/** END: New Main Header */

	constructor(
		private storage: StorageService,
		private cross: CrossFunctionalityService,
		private router: Router,
		private appService: AppService,
		private NotificationInboxService: NotificationInboxService,
		private locationService: LocationService
	) { }
	public makeDashboardScrollable() {
		this.autoScrollDashboard.emit();
	}
	toggleSideMenu() {
		this.showMenu = !this.showMenu;
		if (this.showMenu && this.cross.showNudge) {
			this.cross.hidebyMenu = true;
			this.cross.showNudge = false;
		}
		if (this.showMenu) {
			this.NotificationInboxService.startCount();
		}
	}

	getNavigationBarLeftIcon(): string {
		if (this.router.url === config.dashboard.route) {
			this.isLeftIconLogo = true;
			return this.appService.showNewHeader ? null : TopNavgationBarIconsName.vodafone_Logo;
		} else if (this.appService.showBackButton) {
			this.isLeftIconLogo = false;
			return TopNavgationBarIconsName.left_arrow;
		} else {
			return '';
		}
	}
	getNavigationBarRightIcon(): string {
		if (this.showMenuButton) {
			return TopNavgationBarIconsName.MenuIcon;
		} else if (this.showCloseButton) {
			return TopNavgationBarIconsName.CloseIcon;
		} else {
			return '';
		}
	}
	getMenuIconNotifictionCount(): number {
		if (this.showMenuButton) {
			return this.NotificationInboxService.notificationInboxCount;
		} else {
			return 0;
		}
	}

	checkMenuResponsive(breakpoint: Breakpoints): void {
		if (breakpoint === this.BREAKPOINTS.MOBILE) {
			this.appService.showBackButton = false;
			this.showCloseButton = true;
		} else {
			this.appService.showBackButton = true;
			this.showCloseButton = false;
		}
	}

	myAccountBackButton(): any {
		this.appService.backButtonClicked = true;
		if (this.overrideBackHandling) {
			return this.backBtnSubject.next();
		}
		this.showMenu = false;
		const allModulesStratPoints: string[] = Object.keys(modulesStartPoints);
		if (allModulesStratPoints.find((key) => modulesStartPoints[key] === this.router.url)) {
			this.router.navigate([config.dashboard.route]);
		} else if (this.appService.stepToNavigate === BackNavigation.twoSteps) {
			this.locationService.historyGo(-2);
			this.appService.stepToNavigate = BackNavigation.oneStep;
		} else if (this.appService.stepToNavigate === BackNavigation.threeSteps) {
			this.locationService.historyGo(-3);
			this.appService.stepToNavigate = BackNavigation.oneStep;
		} else if (this.locationService.historyLength() === 2) {
			this.router.navigate([config.dashboard.route]);
		} else if (this.router.url.includes(config.ProductsAndServices.name)) {
			if (this.router.url.includes(config.ProductsAndServices.MainScreenHome.name)) {
				this.router.navigate([config.dashboard.route]);
			} else if (this.router.url.includes(config.ProductsAndServices.MainScreen.name)) {
				this.router.navigate([config.ProductsAndServices.MainScreenHome.route]);
			}
			else {
				this.locationService.back();
			}
		} else if (this.router.url.includes(config.FaultManagement.LandingPage.route)) {
			this.router.navigate([config.dashboard.route]);
		} else {
			this.locationService.back();
		}
	}

	updateMainHeader(): void {
		this.isDashboard = this.router.url === config.dashboard.route;
		const leftIcon: string = this.getNavigationBarLeftIcon();
		this.mainHeaderLeftIcons = !this.showLightTitle() && leftIcon ? [{ icon: leftIcon, iconTitle: 'Atrás' }] : null;

		const rigthIcon: string = this.getNavigationBarRightIcon();
		const notificationCount: number = this.getMenuIconNotifictionCount();
		this.mainHeaderRightIcons = rigthIcon
			? [
				{
					icon: rigthIcon,
					iconTitle: 'Menú',
					notificationBadge: notificationCount ? { text: `${notificationCount}` } : null,
				},
			]
			: null;
	}

	showLightTitle(): boolean {
		return this.storage.userProfile?.hasEnergySites && this.storage.userProfile?.services?.length === 0;
	}
}
