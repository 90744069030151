<mva10-c-card class="smartpay-info-card">
	<div class="smartPay-header-container">
		<div class="title-container">
			<p class="title-text" id="smartpayInfoCardTitle">
				{{ 'v10.productsServices.smartPay.common.easypay' | translate }}
			</p>
		</div>
		<div class="msisdn-container" id="smartpayInfoCardMSISDN">
			<p>{{ msisdn | msisdnToShow }}</p>
		</div>
	</div>
	<div class="status-container">
		<p
			class="status-title"
			[ngClass]="isSmartPay ? 'active-status' : 'inactive-status'"
			id="smartpayInfoCardStatusTitle"
		>
			{{ statusTitle }}
		</p>
		<button
			*ngIf="isSmartPay"
			class="status-button"
			id="smartpayInfoCardDeactivateButton"
			(click)="openDeactivateSmartpayOverlay()"
		>
			{{ 'v10.common.literals.deactivateButton_C' | translate }}
		</button>
	</div>
	<div class="smartPay-Tips">
		<hr />
		<div class="tip-container">
			<img src="{{ indicatorIconPath | imgFullPath }}" class="tip-icon" />
			<p class="tip-text">
				{{ 'v10.productsServices.pagoFacil.upperCard.renewalInfo.text' | translate }}
			</p>
		</div>
		<div class="sizes-tariff-tip tip-container" id="smartpayInfoCardGeneralTip">
			<img src="https://assets-es-sit1.dxlpreprod.local.vodafone.es/assets/images/cost_control.png" class="tip-icon" id="smartpayInfoCardGeneralTipIcon" />
			<p class="tip-text" id="smartpayInfoCardGeneralTipText">
				{{ 'v10.productsServices.pagoFacil.upperCard.termsText' | translate }}
			</p>
		</div>
		<div class="sizes-tariff-tip tip-container" *ngIf="!isBigOrHeavyTariff()" id="smartpayInfoCardSizeTariffTip">
			<img src="https://assets-es-sit1.dxlpreprod.local.vodafone.es/assets/images/gift.png" class="tip-icon" id="smartpayInfoCardSizeTariffTipIcon" />
			<p
				class="tip-text"
				[innerHTML]="'v10.productsServices.smartPay.PyS.benefits.gift' | translate"
				id="smartpayInfoCardSizeTariffTipText"
			></p>
		</div>
	</div>
</mva10-c-card>
