import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PaymentError } from 'src/app/shared/models/payment';
import { PaymentErrorCategoryInWCS } from '../../../shared/enums/payment-error-category-inWCS.enum';
import { PaymentPages } from '../../../shared/enums/payment-pages.enum';
import { PaymentErrorCategory } from '../../../shared/enums/payment-error-category.enum';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentErrorDefault } from './payment-error-default';
import { PaymentErrorInterface } from './payment-error-interface';

export class PaymentErrorStandAloneWalletManagment extends PaymentErrorDefault implements PaymentErrorInterface {
	defaultErrorCategory: PaymentErrorCategory = PaymentErrorCategory.loadingWalletFailed;
	forceShowBackBtn: boolean;
	constructor(
		public paymentNavigationService: PaymentNavigationService,
		public translate: TranslateService,
		public router: Router
	) {
		super(paymentNavigationService, translate, router);
	}

	/**
	 * function to fill error overlay with it's proper
	 * wcs values and btn actions
	 */
	fillErrorOverlay(errorCategory: string): PaymentError {
		if (
			Object.keys(PaymentErrorCategory)
				.map((key) => PaymentErrorCategory[key])
				.find((value) => value === errorCategory)
		) {
			const currentError: PaymentError = new PaymentError();
			const url =
				this.paymentNavigationService.getWcsPath(PaymentPages.ko) + '.' + PaymentErrorCategoryInWCS[errorCategory];
			this.translate.get(url).subscribe((data) => {
				this.currentErrorTitle = data.title;
				currentError.title = data.subtitle;
				currentError.desc = data.description;
				currentError.imgUrl = data.icon;
				currentError.lottieUrl = 'https://assets-es-sit1.dxlpreprod.local.vodafone.es/assets/warning.json';
				currentError.firstBtnText = data.button1;
				currentError.secondBtnText = data.button2;
			});
			this.configPageHeader(errorCategory);
			currentError.firstBtnAction = this.getFirstBtnAction(errorCategory);
			return currentError;
		} else {
			return this.fillErrorOverlay(this.defaultErrorCategory);
		}
	}
	/**
	 * function to configure page x btn
	 */
	configPageHeader(errorCategory: string): void {
		switch (errorCategory) {
			case PaymentErrorCategory.emptyWallet:
				this.forceShowBackBtn = true;
				break;
			default:
				this.forceShowBackBtn = false;
				break;
		}
	}
}
